import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './shared/gaurd/auth.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./modules/master/master.module').then(m => m.MasterModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'login',
    loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule),
  },
  {
    path : 'agreement',
    loadChildren : () => import('../app/modules/agreement/agreement.module').then(m => m.AgreementModule)
  },
  {
    path : 'requestdocument',
    loadChildren : () => import('../app/modules/resend-document/resend-document.module').then(m => m.ResendDocumentModule)
  },
  {
    path: 'createLead',
    loadChildren: () => import('./modules/lead-creation/lead-creation.module').then(m => m.LeadCreationModule),
  },
  {
    path: 'createLeadLybrate',
    loadChildren: () => import('./modules/lead-creation-lybrate/lead-creation-lybrate.module').then(m => m.LeadCreationModule),
  },
  {
    path: '**',
    redirectTo: 'login'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
